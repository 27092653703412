<template lang="pug">

y-map.map(:coords="storages[active].coordinates" :zoom="zoom" :controls="controls")
    y-marker(v-for="(storage, index) in storages" :coords="storage.coordinates" :marker-id="index" :key="index" @click="onClick(index)" :icon="{ color: active == index ? 'green' : 'blue' }")
    //- .fade(:active="active")
    //- .baloon(v-if="active")
    //-     .img
    //-     .name {{ active.address }}

</template>
<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
    props: {
        storages: Array,
        active: Number,
    },
    data: () => ({
        controls: ["routeButtonControl", "geolocationControl", "zoomControl"],
        icon: {
            color: "green",
        },
        zoom: 15,
        default: {
            coords: [56.05258349815037, 92.91684225378802],
            zoom: 12,
        },
    }),
    methods: {
        onClick(index) {
            this.$emit("setActive", index);
        },
    },
    components: {
        "y-map": yandexMap,
        "y-marker": ymapMarker,
    },
};
</script>
<style lang="stylus" scoped>

.map
    position relative
    width 100%
    margin-left 50px
    height 400px
    border-radius 15px
    $medium-shadow()
    overflow hidden
    +$tablet()
        margin-left 35px
    +$phone()
        margin-left 0
        margin-top 20px

.fade
    position absolute
    z-index 2
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(#000, .1)
    cursor pointer
    border-radius 15px

// .baloon
//     position absolute
//     z-index 3
//     width 300px
//     height 300px
//     border-radius 10px
//     background-color $bg-choosen
//     $medium-shadow()
</style>
