<template lang="pug">

.popup.verification(:class="{ phone: query == 'phone', email: query == 'email' }")
    .message.error(v-if="error !== null") {{ messages[error] }}
    .message(v-else-if="query == 'phone'") Для проверки номера {{ user.phone }} вам отправлен смс-код
    .message(v-else-if="query == 'email'") Для проверки электронной почты {{ user.email }} вам на почту отправлен код
    form.form(@submit.prevent="submit")
        input.input.input-code(v-mask="query == 'phone' ? '####' : '######'" name="code" required placeholder="Код" v-model="code")
        .code-help(v-if="$store.state.auth.codes[query]") Код верификации (тест): {{ $store.state.auth.codes[query] }}
        .submit.submit-verification(v-if="error === 1" @click="newCode") Заказать новый код
        button.submit.submit-verification(v-else type="submit") Проверить

</template>
<script>
import { VueMaskDirective } from 'v-mask'
export default {
    props: {
        query: String,
    },
    mounted() {
        this.$el.querySelector('.form .input').focus()
    },
    data: () => ({
        code: null,
        error: null,
        messages: [
            'Введённый код неверен',
            'Время действия кода закончилось (10 мин), закажите новый',
            'Ваш аккаунт уже верифицирован',
        ],
    }),
    methods: {
        async submit() {
            const res = await this.$store.dispatch('auth/setVerification', {
                code: this.code,
                type: this.query,
            })
            if (res === true)
                this.$store.commit('other/popup', { name: null, query: null })
            else this.error = res
        },
        newCode() {
            this.$store.dispatch('auth/getVerification')
            this.error = null
        },
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
    },
    directives: {
        mask: VueMaskDirective,
    },
}
</script>
<style lang="stylus" scoped>

.popup.verification
    padding 30px 25px 30px
    width 280px
    display flex
    align-items center
    flex-direction column
    &.phone
        .input
            width 70px
    &.email
        .input
            width 80px


.form
    display flex
    align-items center
    flex-direction column

.message
    font-size 16px
    text-align center
    color $cant-be-choosen
    &.error
        color $red

.input
    height 45px
    background-color #fff
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.04), inset 0px 1px 2px rgba(0, 0, 0, 0.04)
    border-radius 10px
    font-size 18px
    color $can-push
    border 1px solid transparent
    margin-top 15px
    transition all .1s
    width 70px
    text-align center
    padding 0 9px
    &::placeholder
        color $cant-be-choosen

.submit
    margin-top 30px
    height 46px
    width auto
    position relative
    border-radius 10px
    color $black
    $medium-shadow()
    $bordered()
    font-size 22px
    display flex
    align-items center
    cursor pointer
    margin-top 20px
    padding 0 20px

.code-help
    font-size 16px
    margin-top 20px
    text-align center
</style>
