import Axios from 'axios'

export default {
    namespaced: true,
    state: () => ({
        help: {
            loaded: false,
            articles: [],
        },
        home: {
            loaded: false,
            welcome: {},
            ads: [],
            about: [],
            reviews: [],
            contacts: [],
        },
        contacts: {
            loaded: false,
            storages: [],
            owners: [],
        },
        cart: {
            loaded: false,
            storages: [],
        },
        names: {
            months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
            ],
            monthsInDate: [
                'Января',
                'Февраля',
                'Марта',
                'Апреля',
                'Мая',
                'Июня',
                'Июля',
                'Августа',
                'Сентября',
                'Октября',
                'Ноября',
                'Декабря',
            ],
            days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        },
    }),
    getters: {
        page: (state) => (page) => state[page],

        names: ({ names }) => names,
    },
    mutations: {},
    actions: {
        async home({ state: { home } }) {
            const { data } = await Axios.get('/api/page/home/')

            home.welcome = data.welcome
            home.about = data.about
            home.reviews = data.reviews
            home.contacts = data.storages

            home.loaded = true
        },
        async contacts({ state: { contacts } }) {
            const { data } = await Axios.get('/api/page/contacts/')
            console.log('/api/page/contacts/', data)

            contacts.storages = data.storages
            contacts.owners = data.owners

            contacts.loaded = true
        },
        async help({ state: { help } }) {
            const { data } = await Axios.get('/api/page/help/')
            console.log('/api/page/help/', data)

            help.articles = data.articles

            help.loaded = true
        },
        async cart({ state: { cart } }) {
            const { data } = await Axios.get('/api/page/cart-test/')
            console.log('/api/page/cart/', data)

            cart.storages = data.storages

            cart.loaded = true
        },
    },
}
