import Axios from 'axios'
import Cookies from 'js-cookie'
import router from '../router'

export default {
    namespaced: true,
    state: () => ({
        products: {
            show: [],
            cart: [],
            all: [],
            favorites: [],
        },
        categories: [],
        status: {
            loaded: {
                catalog: false,
                cart: false,
            },
            loading: false,
            sum: 0,
            found: 0,
            sync: 0,
        },
        options: {
            category: null,
            sort: '-popularity',
            search: '',
            offset: 0,
            order: {
                address: {
                    delivery: false,
                    address: null,
                },
                arrival: {
                    date: null,
                    time: null,
                },
            },
        },
    }),
    getters: {
        show: ({ products }) => products.show,
        cart: ({ products }) => products.cart,
        categories: ({ categories }) => categories,

        product:
            ({ products: { all } }) =>
            (id) =>
                all.find((item) => item.id == id),
        category:
            ({ categories }) =>
            (id) =>
                categories.find((item) => item.id == id),
        favorite:
            ({ products: { favorites } }) =>
            (id) =>
                favorites.indexOf(id) != -1 ? true : false,

        options: ({ options }) => options,
        optionsOrder: ({ options: { order } }) => order,

        found: ({ status }) => status.found,
        sum: ({ status }) => Math.abs(status.sum.toFixed(2)),
        loaded: ({ status }) => status.loaded,
        loading: ({ status }) => status.loading,
    },
    mutations: {
        setShownProducts({ products: { all, show }, options }, products) {
            if (options.offset == 0) show.splice(0)

            for (let product of products) {
                const loaded = all.find((item) => item.id == product.id)

                if (loaded) show.push(loaded)
                else {
                    all.push(product)
                    show.push(product)
                }
            }
        },

        setCart({ products, status }, cart) {
            let sum = 0
            for (let product of cart) {
                let loaded = products.all.find((item) => item.id == product.id)
                if (!loaded) {
                    product.price = parseFloat(product.price)
                    product.sum = parseFloat(product.sum)
                    products.all.push(product)
                    products.cart.push(product)
                } else if (!loaded.cart) {
                    loaded.count = product.count
                    loaded.unit = product.unit
                    loaded.price = parseFloat(product.price)
                    loaded.sum = parseFloat(product.sum)
                    loaded.cart = true
                    products.cart.push(loaded)
                }
                sum += loaded ? loaded.sum : product.sum
            }
            status.sum = sum
        },

        clearCart({ products, status }) {
            for (let product of products.cart) {
                product.count = 0
                product.unit = 0
                product.sum = 0
                product.cart = false
            }
            products.cart.splice(0)

            status.sum = 0
            status.sync++
        },

        setProduct({ products, status }, { product, count }) {
            count = count > 0 ? count : 0

            status.sum += (count - product.count) * product.price

            product.count = count
            product.sum = product.count * product.price

            if (!product.cart && count > 0) {
                product.cart = true
                products.cart.push(product)
            }

            status.sync++
        },

        removeProduct({ products, status }, product) {
            status.sum -= product.sum
            product.count = 0
            product.unit = 0
            product.sum = 0
            product.cart = false
            products.cart.splice(
                products.cart.findIndex((item) => item.id == product.id),
                1,
            )
            status.sync++
        },

        optionsOrder({ options: { order } }, { field, value }) {
            order[field] = value
        },

        setFilterParams({ options, categories }, params) {
            options.search = params.search || ''
            options.sort = params.sort
            options.category =
                categories.find((category) => category.id == params.category) ||
                null
        },
    },
    actions: {
        async init({ commit, dispatch, state: { categories, status } }) {
            status.loading = true

            console.log(localStorage.getItem('filterParams'))

            const { data: catalog } = await Axios.get('/api/catalog/')
            console.log('/api/catalog/', catalog)

            if (!status.loaded.cart) await dispatch('loadCart')

            categories.push(...catalog.categories)
            status.loaded.catalog = true

            const filterParams = localStorage.getItem('filterParams')
            console.log(
                'saved filter params',
                filterParams ? JSON.parse(filterParams) : null,
            )

            if (filterParams) {
                commit('setFilterParams', JSON.parse(filterParams))
                await dispatch('filter', {})
            } else {
                commit('setShownProducts', catalog.products)

                status.found = catalog.found
                status.loading = false
            }
        },

        async loadCart({
            commit,
            dispatch,
            state: { products, status },
            rootGetters,
        }) {
            if (rootGetters['auth/auth']) {
                const {
                    data: { cart, favorites },
                } = await Axios.get('/api/user/catalog/')

                products.favorites = favorites
                commit('setCart', cart)
            } else {
                // let list = Cookies.get('cart')
                let list = localStorage.getItem('cart')
                if (list) {
                    list = JSON.parse(list)
                    const {
                        data: { cart },
                    } = await Axios.post('/api/catalog/products/', list)

                    commit('setCart', cart)
                }
            }
            dispatch('syncCart')
            status.sync++
        },

        async filter(
            { commit, state: { options, status } },
            { name, value = null },
        ) {
            status.loading = true

            if (name == 'sort') options.sort = value
            if (name == 'search') options.search = value
            if (name == 'category') options.category = value
            if (name == 'offset') options.offset++
            else options.offset = 0

            const params = {
                category: options.category ? options.category.id : null,
                search: options.search.length > 0 ? options.search : null,
                sort: options.sort,
                offset: options.offset,
            }

            console.log(params)
            localStorage.setItem('filterParams', JSON.stringify(params))

            const {
                data: { products, found },
            } = await Axios.post('/api/catalog/filter/', params)
            console.log('/api/catalog/filter/', { products, found })

            status.found = found
            commit('setShownProducts', products)

            status.loading = false
        },

        async favorite(
            {
                state: {
                    products: { favorites },
                },
            },
            product,
        ) {
            try {
                await Axios.post('/api/user/favorite/', { id: product.id })

                let index = favorites.indexOf(product.id)
                if (index != -1) favorites.splice(index, 1)
                else favorites.push(product.id)
            } catch (error) {
                console.log(error)
            }
        },

        async syncCart({ state: { status, products }, rootGetters }) {
            let syncID = status.sync
            setInterval(async () => {
                if (status.sync == syncID) return

                syncID = status.sync

                let cart = []

                for (let product of products.cart)
                    cart.push({
                        id: product.id,
                        count: product.count,
                        unit: product.unit,
                    })

                if (rootGetters['auth/auth'])
                    await Axios.post('/api/user/cart/', cart)
                else localStorage.setItem('cart', JSON.stringify(cart))
                // Cookies.set('cart', JSON.stringify(cart), {
                //     secure: true,
                //     sameSite: 'strict',
                // })
            }, 5000)
        },

        async sendOrder({
            state: {
                products,
                options: { order },
            },
            commit,
            rootState,
        }) {
            let cart = [],
                arrival = order.arrival,
                address = order.address

            address.address = rootState.info.cart.storages[address.address].id

            for (let product of products.cart)
                if (product.count > 0)
                    cart.push({
                        id: product.id,
                        count: product.count,
                        unit: product.package[product.unit],
                    })

            try {
                const { data } = await Axios.post('/api/user/new_order/', {
                    cart,
                    arrival: {
                        year: arrival.date.year,
                        month: arrival.date.month + 1,
                        day: arrival.date.day,
                        hour: arrival.time,
                    },
                    address: {
                        delivery: address.delivery,
                        address: address.address,
                    },
                })
                console.log(data)
                if (data === false) return false

                commit('profile/forget', 'active', { root: true })
                commit('clearCart')
                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
    },
}
