import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import ModuleAuth from './auth'
import ModuleCatalog from './catalog'
import ModuleInfo from './info'
import ModuleProfile from './profile'
import ModuleOther from './other'

const store = new Vuex.Store({
	modules: {
		auth: ModuleAuth,
		catalog: ModuleCatalog,
		info: ModuleInfo,
		profile: ModuleProfile,
		other: ModuleOther,
	},
	actions: {
		init({ dispatch, commit }) {
		}
	}
})

export default store
