<template lang="pug">

v-storages#contacts(:storages="storages" title="Как нас найти?")

</template>
<script>
import vStorages from "ui/storages";
export default {
    computed: {
        storages() {
            return this.$store.getters["info/page"]("home").contacts;
        },
    },
    components: {
        vStorages,
    },
};
</script>
<style lang="stylus" scoped>

.storages
    margin-top 140px
    margin-bottom 75px
    +$phone()
        margin-top 60px
</style>
