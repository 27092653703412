import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from 'core/store'

import Home from 'templates/home'

const router = new VueRouter({
    // mode: process.env.VUE_APP_SERVER == 'local' ? 'hash' : 'history',
    mode: 'history',
    routes: [
        {
            path: '*',
            name: '404',
            component: () => import('templates/404'),
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            beforeEnter(to, from, next) {
                if (!store.getters['info/page']('home').loaded)
                    store.dispatch('info/home')
                next()
            },
        },
        {
            path: '/catalog/',
            component: () => import('templates/catalog'),
            beforeEnter(to, from, next) {
                if (!store.getters['catalog/loaded'].catalog)
                    store.dispatch('catalog/init')
                next()
            },
            children: [
                {
                    path: '',
                    name: 'catalog',
                    // beforeEnter(to, from, next) {
                    //     store.state.catalog.options.search =
                    //         to.query.search || ''

                    //     next()
                    // },
                },
                {
                    path: 'cart',
                    name: 'cart',
                    beforeEnter(to, from, next) {
                        if (!store.getters['info/page']('cart').loaded)
                            store.dispatch('info/cart')
                        next()
                    },
                },
            ],
        },
        {
            path: '/help/',
            name: 'help',
            component: () => import('templates/help'),
            redirect: '/help/delivery',
            beforeEnter(to, from, next) {
                if (!store.getters['info/page']('help').loaded)
                    store.dispatch('info/help')
                next()
            },
            children: [
                {
                    path: ':name',
                    props: true,
                    component: () => import('templates/help/article'),
                },
            ],
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: () => import('templates/contacts'),
            beforeEnter(to, from, next) {
                if (!store.getters['info/page']('contacts').loaded)
                    store.dispatch('info/contacts')
                next()
            },
        },
        {
            path: '/profile/',
            name: 'profile',
            redirect: { name: 'active' },
            component: () => import('templates/profile'),
            beforeEnter(to, from, next) {
                if (store.getters['auth/auth']) next()
                else next('/')
            },
            children: [
                {
                    path: 'active',
                    name: 'active',
                    component: () => import('templates/profile/active'),
                    beforeEnter(to, from, next) {
                        if (!store.getters['profile/active'].status.loaded)
                            store.dispatch('profile/loadOrders', 'active')
                        next()
                    },
                },
                {
                    path: 'history',
                    name: 'history',
                    component: () => import('templates/profile/history'),
                    beforeEnter(to, from, next) {
                        if (!store.getters['profile/history'].status.loaded)
                            store.dispatch('profile/loadOrders', 'history')
                        next()
                    },
                },
                // {
                // 	path: 'bonus',
                // 	name: 'bonus',
                // 	component: () => import('templates/profile/bonus'),
                // },
                {
                    path: 'settings',
                    name: 'settings',
                    component: () => import('templates/profile/settings'),
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.name == from.name) return
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default router
