import { render, staticRenderFns } from "./reviews.vue?vue&type=template&id=15a0f79d&scoped=true&lang=pug"
import script from "./reviews.vue?vue&type=script&lang=js"
export * from "./reviews.vue?vue&type=script&lang=js"
import style0 from "./reviews.vue?vue&type=style&index=0&id=15a0f79d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a0f79d",
  null
  
)

export default component.exports