import Axios from 'axios'
import router from 'core/router'
import Cookies from 'js-cookie'

export default {
    namespaced: true,
    state: () => ({
        auth: false,
        loaded: false,
        user: {
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            addresses: [],
            verificated: false,
            email_verificated: false,
        },
        codes: {
            phone: null,
            email: null,
        },
    }),
    getters: {
        auth: ({ auth }) => auth,
        loaded: ({ loaded }) => loaded,
        user: ({ user }) => user,
    },
    mutations: {
        setUser({ user }, data) {
            if (data) {
                user.phone = data.phone
                user.email = data.email
                user.first_name = data.first_name
                user.last_name = data.last_name
                user.verificated = data.verificated
                user.email_verificated = data.email_verificated
                user.addresses = data.addresses
            } else {
                user.phone = null
                user.email = null
                user.first_name = null
                user.last_name = null
                user.verificated = null
                user.email_verificated = null
                user.addresses = []
            }
        },
        setToken() {
            Axios.defaults.headers.common['X-CSRFToken'] =
                Cookies.get('csrftoken')
        },
    },
    actions: {
        async init({ state, commit }) {
            if (window.auth) state.auth = window.auth
            const { data: user } = await Axios.get('/api/user/')

            commit('setToken')
            state.loaded = true

            if (!user) return

            state.auth = true
            commit('setUser', user)
        },
        async login({ commit, state, dispatch }, req) {
            if (state.auth) return
            try {
                const { data } = await Axios.post('/api/auth/login/', req)

                if (data === false) return false

                commit('setToken')
                state.auth = true
                commit('setUser', data)
                dispatch('catalog/loadCart', null, { root: true })

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async logout({ commit, state, dispatch }) {
            if (!state.auth) return
            try {
                await Axios.post('/api/auth/logout/')

                commit('setToken')
                state.auth = false
                commit('setUser', null)
                commit('catalog/clearCart', null, { root: true })
                dispatch('catalog/loadCart', null, { root: true })

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async signup({ commit, state }, payload) {
            if (state.auth) return
            try {
                const { data } = await Axios.post('/api/auth/signup/', payload)
                if (data === 1 || data === 2) return data
                commit('setToken')
                state.auth = true
                commit('setUser', data)
                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async edit({ commit }, { field, value }) {
            try {
                const { data } = await Axios.post('/api/auth/edit/', {
                    field,
                    value,
                })

                if (data === false) return false

                commit('setUser', data)
                return true
            } catch (error) {
                console.log(error)
            }
        },
        async getVerification({ commit, state }, type = 'phone') {
            try {
                const { data } = await Axios.get(
                    '/api/auth/verification/' + type + '/',
                )
                console.log(data)

                if (data) state.codes[type] = data

                commit(
                    'other/popup',
                    { name: 'verification', query: type },
                    { root: true },
                )

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
        async setVerification({ state }, { code, type }) {
            try {
                const { data } = await Axios.post(
                    '/api/auth/verification/' + type + '/',
                    { code },
                )

                if (data !== true) return data

                if (type == 'phone') state.user.verificated = true
                else type == 'email'
                state.user.email_verificated = true

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },
    },
}
