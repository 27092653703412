<template lang="pug">

form.form(@submit.prevent="submit")
    .message.error(v-if="error") Номер или пароль введены неверно либо пользователь не зарегестрирван
    .input
        input(v-model="phone" name="username" type="phone" v-mask="'+7 ### ### ##-##'" placeholder="Номер телефона" required)
    .input.password
        input(placeholder="Пароль" name="password" v-model="password" type="password" required)
    .meta
        .remember(:class="{ active: remember }" @click="remember = !remember") Запомнить меня
            g-icon(name="ui/ok")
        .forget Забыли пароль?
    button.submit.submit-login(type="submit") Войти

</template>
<script>
import { VueMaskDirective } from 'v-mask'
export default {
    mounted() {
        this.$el.querySelector('.form .input input[type="phone"]').focus()
    },
    data: () => ({
        remember: false,
        phone: null,
        password: null,
        error: null,
    }),
    methods: {
        async submit() {
            this.error = null
            let phone = this.phone.split(' ').join('')
            phone = phone.split('-').join('')

            const res = await this.$store.dispatch('auth/login', {
                phone,
                password: this.password,
            })

            if (res === true)
                this.$store.commit('other/popup', { name: null, query: null })
            else if (res === false) this.error = true
        },
    },
    directives: {
        mask: VueMaskDirective,
    },
}
</script>
<style lang="stylus" scoped>

.meta
    margin-top 15px
    display flex
    justify-content space-between
    align-items center
    width 100%

.remember
    user-select none
    font-size 14px
    background-color $light-grey
    $small-shadow()
    border-radius 8px
    color $grey
    font-size 14px
    height 30px
    display inline-flex
    align-items center
    padding 2px 7px 0 30px
    position relative
    cursor pointer
    border: 1.5px solid transparent
    transition all .25s, box-shadow .2s
    &:hover
        box-shadow 0px 1px 2px rgba(#000, 0.04)
    &:active
        box-shadow 0px 1px 1px rgba(#000, 0.03)
    &::after
        content ''
        position absolute
        width 20px
        height 20px
        background-color #fff
        border-radius 4px
        top 4px
        left 5px
        transition all .25s
    &.active
        background-color #fff
        box-shadow none
        border-color $light-grey
        &::after
            opacity 0
        &:hover
            box-shadow none
        .icon
            opacity 1

    .icon
        width 12px
        position absolute
        top 6px
        left 9px
        z-index 2
        transition opacity .25s
        opacity 0
        fill $green

.forget
    font-size 14px
    color $grey
    cursor pointer
</style>
