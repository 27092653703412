<template lang="pug">

form.form(@submit.prevent="submit")
    .message.error(v-if="error !== null") {{ messages[error] }}
    .input(:class="{ success: nameValidation, error: nameValidation === false }")
        input(placeholder="Имя" v-model="name" name="reg_firstname" required)
        .help
            .help-btn ?
            .help-content Поле Имя должно содержать от 2 до 20 кириллических символов
    .input(:class="{ success: surnameValidation, error: surnameValidation === false }")
        input(placeholder="Фамилия" v-model="surname" name="reg_surname" required)
        .help
            .help-btn ?
            .help-content Поле Фамилия должно содержать от 2 до 20 кириллических символов
    .input(:class="{ success: phoneValidation, error: phoneValidation === false }")
        input(v-model="phone" name="phone" type="tel" v-mask="'+7 ### ### ##-##'" placeholder="Номер телефона" required)
    .input.password(:class="{ success: passwordValidation, error: passwordValidation === false }")
        input(:class="{ invalid: error === 0 }" placeholder="Пароль" v-model="password" name="reg_password" type="password" required)
        .help
            .help-btn ?
            .help-content Поле Пароль должно содержать не менее 6 символов кириллицей, содержать буквы верхнего и нижнего регистров, числовые значения и специальные символы
    .input.password
        input(:class="{ invalid: error === 0 }" placeholder="Повторите пароль" name="reg_repeat" v-model="repeat" type="password" required)
    .password-error(v-if="password && repeat") {{ password == repeat ? 'Пароли совпадают' : 'Пароли несовпадают' }}
    button.submit.submit-signup(type="submit") Зарегестрироваться

</template>
<script>
import { VueMaskDirective } from 'v-mask'
export default {
    mounted() {
        // this.$el.querySelector('.form .input input[name="phone"]')
    },
    data: () => ({
        phone: null,
        name: null,
        surname: null,
        password: null,
        repeat: null,
        // 0 - пароли не совпадают
        // 1 - пользователь уже зарегесрирован
        // 2 - номере не существует
        error: null,
        messages: [
            'Введёные пароли не совпадают',
            'Пользователь с данным номером уже зарегестриован',
            'Данного номера не существует',
        ],
    }),
    computed: {
        nameValidation() {
            if (!this.name) return null

            if (this.name.length < 2 || this.name.length > 19) return false
            if (
                !this.name.match(
                    /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/,
                )
            )
                return false

            return true
        },
        surnameValidation() {
            if (!this.surname) return null

            if (this.surname.length < 2 || this.surname.length > 19)
                return false

            return true
        },
        phoneValidation() {
            if (!this.phone) return null

            let phone = this.phone.split(' ').join('')
            phone = phone.split('-').join('')

            if (phone.length < 12) return false

            return true
        },
        passwordValidation() {
            if (!this.password) return null

            if (this.password.length < 6) return false
            if (!/\d/.test(this.password)) return false

            return true
        },
    },
    methods: {
        async submit() {
            if (this.password != this.repeat) {
                this.error = 0
                return
            } else this.error = null
            let phone = this.phone.split(' ').join('')
            phone = phone.split('-').join('')

            let res = await this.$store.dispatch('auth/signup', {
                phone,
                password: this.password,
                name: this.name,
                surname: this.surname,
            })

            if (res === 1) this.error = 1
            if (res === true) {
                this.$store.dispatch('auth/getVerification')
            }
        },
    },
    directives: {
        mask: VueMaskDirective,
    },
}
</script>
<style scoped lang="stylus">

.password-error
    font-size: 14px
    // text-align right
    color red
    width 100%
    margin-top 7px
</style>
