<template lang="pug">

.header(:class="{ fixed }")
    .container

        .logo(v-if="$route.name == 'home'" v-scroll-to="{ el: '#welcome', offset: -200 }") sklad trade
        .logo(v-else-if="$route.name == 'catalog' || $route.name == 'cart'") sklad trade
        router-link.logo(v-else :to="{ name: 'home' }") sklad trade


        .menu-btn.hamburger.hamburger--spin(:class="{ 'is-active': !mobileMenuHidden }" @click="mobileMenuHidden = !mobileMenuHidden")
            .hamburger-box
                .hamburger-inner

        .menu(:class="{ hidden: mobileMenuHidden }")
            .link.active(v-if="$route.name == 'home'" @click="mobileMenuHidden = true" v-scroll-to="{ el: '#welcome', offset: -200 }") Главная
            router-link.link(v-else :to="{ name: 'home' }" @click.native="mobileMenuHidden = true" exact) Главная
            router-link.link(:to="{ name: 'catalog' }" @click.native="mobileMenuHidden = true") Каталог
            router-link.link(:to="{ name: 'contacts' }" @click.native="mobileMenuHidden = true") Контакты
            router-link.link(:to="{ name: 'help' }" @click.native="mobileMenuHidden = true") Помощь
            router-link.link.link-profile(v-if="!loaded || auth" :to="{ name: 'profile' }" @click.native="mobileMenuHidden = true")
                g-icon(name="profile/profile")
                | {{ user.first_name }} {{ user.last_name }}
            template(v-else)
                .link(@click="login") Войти
                .link(@click="signup") Регистрация

</template>
<script>
export default {
    mounted() {
        this.setFixed()
        window.addEventListener('scroll', this.setFixed)
    },
    data: () => ({
        fixed: false,
        nav: [
            {
                title: 'Каталог',
                name: 'catalog',
            },
            {
                title: 'Контакты',
                name: 'contacts',
            },
            {
                title: 'Помощь',
                name: 'help',
            },
        ],
        mobileMenuHidden: true,
    }),
    computed: {
        auth() {
            return this.$store.getters['auth/auth']
        },
        loaded() {
            return this.$store.getters['auth/loaded']
        },
        user() {
            return this.$store.getters['auth/user']
        },
    },
    methods: {
        setFixed() {
            if (window.pageYOffset > 10) this.fixed = true
            else this.fixed = false
        },
        async linkContacts() {
            if (this.$route.name != 'home') {
                this.$router.push({ name: 'home' })
                await this.$nextTick()
            }
            this.$scrollTo('#contacts', 300, {
                offset: -window.innerHeight / 4,
            })
        },
        login() {
            this.mobileMenuHidden = true
            this.$store.commit('other/popup', { name: 'auth', query: 'login' })
        },
        signup() {
            this.mobileMenuHidden = true
            this.$store.commit('other/popup', {
                name: 'auth',
                query: 'signup',
            })
        },
    },
}
</script>
<style lang="stylus" scoped>
@import "hamburgers/dist/hamburgers.min.css"

.header
    position fixed
    width 1360px
    top 10px
    left calc(50vw - 685px)
    height 70px
    padding 0 30px
    border-radius 8px
    box-shadow none
    background transparent
    transition all .25s
    z-index 20
    +$tablet()
        width calc(100% - 20px)
        padding 0 15px
        left 10px
        top 10px
    +$phone()
        top 5px
        left 5px
        width auto
        right 5px
        height auto
        padding 12px 14px
    &.fixed
        background $bg-choosen
        $small-shadow()

.container
    position relative
    // width 1300px
    width 100%
    height 100%
    margin auto
    display flex
    align-items center
    justify-content space-between
    flex-shrink 0

.logo
    font-size 30px
    text-transform uppercase
    color $green
    cursor pointer
    +$phone()
        font-size 20px

.menu
    display flex
    align-items center

    +$phone()
        position fixed
        top 0
        left 0
        right 0
        bottom 0
        background-color #fff
        flex-direction column
        justify-content space-between
        z-index -1
        padding 130px 0 130px
        &.hidden
            display none

    &-btn
        display none
        padding: 0
        &:hover
            opacity: 1
        .hamburger-box
            width: 27px
            height 20px
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before
            height: 2px
            width: 100%
            background-color: #333
        &:not(.is-active)
            .hamburger-inner
                &::after
                    bottom 7px
                &::before
                    top 7px
        +$phone()
            display flex


.link
    font-size 20px
    font-weight 500
    margin-left 32px
    color $grey
    cursor pointer
    +$phone()
        margin-left 0
        font-size 22px
    &-profile
        display flex
        align-items center
        margin-left 50px
        +$phone()
            margin-left 0
        .icon
            margin-right 10px
            height 22px
            fill $grey
            display inline-block
            // +$phone()
            //     display none

    &.router-link-active, &.active
        color $green
        .icon
            fill $green

.login
    display flex
    margin-left 18px
    +$phone()
        flex-direction column
        margin-left 0
        text-align center
</style>
