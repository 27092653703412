<template lang="pug">

v-flickity#ads.ads(ref="flickity" :options="sliderOptions")
    .slide(v-for="slide in slides")
    .prev(v-if="index > 1" @click="$refs.flickity.previous()")
        g-icon(name="ui/chevron")
    .next(v-if="index < slides.length - 2" @click="$refs.flickity.next()")
        g-icon(name="ui/chevron")

</template>
<script>
import vFlickity from 'vue-flickity'
export default {
    data: (context) => ({
        slides: [
            'Акция',
            'Акция',
            'Акция',
            'Акция',
            'Акция',
        ],
        index: 1,
        sliderOptions: {
            initialIndex: 1,
            prevNextButtons: false,
            autoPlay: 5000,
            pageDots: true,
            cellSelector: '.slide',
            on: {
                select: (index) => context.select(index)
            }
        },
    }),
    methods: {
        select(index) {
            this.index = index
            if (index < 1)
                this.$refs.flickity.select(1)
            else if (index > this.slides.length - 2)
                this.$refs.flickity.select(this.slides.length - 2)
        }
    },
    components: {
        vFlickity
    },
}
</script>
<style lang="stylus" scoped>

.ads
    left -10px
    width calc(100% + 20px)
    position relative

.slide
    width 350px
    height 250px
    background-color $bg-choosen
    $small-shadow()
    border-radius 15px
    margin 0 10px 10px

>>> .flickity-page-dots
    bottom -15px
    .dot
        opacity 1
        width 7px
        height 7px
        background-color $light-grey
        transition background-color .2s
        margin 0 5px
        &.is-selected
            background-color $blue
        &:first-child, &:last-child
            display none

.next, .prev
    position absolute
    top 50%
    margin-top -15px
    cursor pointer
    transition all .1s
    .icon
        width 30px
        fill $minus
    &:hover
        opacity .8

.prev
    left -35px
    transform rotate(-90deg)
.next
    right -35px
    transform rotate(90deg)

</style>
