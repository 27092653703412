<template lang="pug">

.product
    .left
        g-icon(:name="'products/' + product.img").picture
        .name {{ product.name }}
    .right
        .price {{ product.price }} ₽
        .sum {{ (product.price * product.count).toFixed(2) }} ₽
        .count {{ product.count }} ед

</template>
<script>
export default {
    props: {
        product: Object,
    },
}
</script>
<style lang="stylus" scoped>

.product
    position relative
    display flex
    justify-content space-between
    background-color $bg-choosen
    $small-shadow()
    border-radius 10px
    margin-bottom 10px
    height 60px
    padding 0 12px
    .left, .right
        display flex
        align-items center
    &:last-child
        margin-bottom 0

.picture
    width 30px
    height 30px
    fill $grey
    transition fill .2s

.name
    margin-left 12px
    color $grey
    font-size 18px
    transition color .2s
    max-width 270px

.price, .sum
    padding 3px 8px 2px
    border-radius 7px
    font-size 18px
    white-space nowrap

.price
    background-color $bg
    box-shadow inset 0px -1px 2px rgba(0, 0, 0, 0.04), inset 0px 1px 2px rgba(0, 0, 0, 0.04)
    color $grey
    transition color .15s

.sum
    background-color $light-grey
    $small-shadow()
    color $can-choose
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.04), inset 0px 1px 2px rgba(0, 0, 0, 0.04)
    position relative
    margin-left 20px
    &::after
        content ''
        position absolute
        height 2px
        width 8px
        top 50%
        margin-top -1px
        left -13px
        border-radius 3px
        background-color $grey

.count
    font-size 20px
    line-height 24px
    color $can-choose
    margin-left 35px

</style>
