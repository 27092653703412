<template lang="pug">

.popup.order
    .exit(@click="$emit('close')")
        g-icon(name="ui/exit")
    v-info(:order="order")
    .cart
        v-product(v-for="product in order.cart" :product="product" :key="product.id")
    v-options(:status="order.status" @cancel="cancel()")

</template>
<script>
import vInfo from './info'
import vProduct from './product'
import vOptions from './options'
export default {
    props: {
        query: Number,
    },
    data: () => ({
        default: {
            status: 0,
            id: 0,
            sum: 0,
            count: 0,
            points: 0,
            date: '',
            cart: [],
        },
    }),
    computed: {
        order() {
            return (
                this.$store.getters['profile/order'](this.query) || this.default
            )
        },
    },
    methods: {
        cancel() {
            this.$store.dispatch('profile/cancelOrder', parseInt(this.query))
            this.$emit('close')
        },
    },
    components: {
        vInfo,
        vProduct,
        vOptions,
    },
}
</script>
<style lang="stylus" scoped>

.popup.order
    width 720px
    padding 25px 20px 20px

.exit
    position absolute
    top 18px
    right 19px
    width 30px
    height 30px
    border-radius 7px
    background-color $light-grey
    cursor pointer
    .icon
        stroke $can-push
        stroke-width 2px
        width 20px
        position absolute
        top 5px
        left 5px

.cart
    margin-top 10px
</style>
