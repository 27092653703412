import Axios from 'axios'
import router from 'core/router'

export default {
    namespaced: true,
    state: () => ({
        history: {
            orders: [],
            status: {
                found: 0,
                loaded: false,
            },
            options: {
                sort: '-created',
                offset: 0,
                date: null,
            },
        },
        active: {
            orders: [],
            status: {
                found: 0,
                loaded: false,
            },
            options: {
                sort: '-created',
                offset: 0,
                date: null,
            },
        },
    }),
    getters: {
        history: (state) => state.history,
        active: (state) => state.active,
        order:
            ({ history, active }) =>
            (id) =>
                active.orders.find((item) => item.id == id) ||
                history.orders.find((item) => item.id == id),
    },
    mutations: {
        forget(state, type) {
            state[type].orders.splice(0)
            state[type].status.loaded = false
            state[type].status.found = false
            state[type].options.sort = '-created'
            state[type].options.offset = 0
            state[type].options.date = null
        },
    },
    actions: {
        async loadOrders({ dispatch, state }, type) {
            try {
                const {
                    data: { list, found },
                } = await Axios.get('/api/user/orders/' + type + '/')

                dispatch('setOrders', { list, found, type })
                state[type].status.loaded = true
            } catch (error) {
                console.log(error)
            }
        },
        async filterOrders({ state, dispatch }, { type, name, value = null }) {
            const options = state[type].options
            if (name == 'sort') options.sort = value
            if (name == 'date') options.date = value
            if (name == 'offset') options.offset++
            else options.offset = 0
            try {
                const {
                    data: { list, found },
                } = await Axios.post('/api/user/orders/' + type + '/', {
                    sort: options.sort,
                    date: options.date,
                    offset: options.offset,
                })

                dispatch('setOrders', { list, found, type })
            } catch (error) {
                console.log(error)
            }
        },
        setOrders({ state, rootGetters }, { list, found, type }) {
            const monthNames = rootGetters['info/names'].monthsInDate

            let orders = state[type]
            if (orders.options.offset == 0) orders.orders.splice(0)

            for (let order of list) {
                let ready = orders.orders.find((item) => item.id == order.id)

                if (ready) orders.orders.push(ready)
                else {
                    order.date = strDate(new Date(order.arrival))
                    order.created = strDate(new Date(order.created))

                    orders.orders.push(order)
                }
            }

            state[type].status.found = found

            function strDate(date) {
                let day = date.getDate(),
                    month = monthNames[date.getMonth()],
                    year = date.getFullYear(),
                    hours = date.getHours(),
                    minutes = date.getMinutes()
                if (minutes < 10) minutes = '0' + minutes

                return `${day} ${month} ${year} ${hours}:${minutes}`
            }
        },

        async cancelOrder({ state }, id) {
            const { data } = await Axios.post(
                '/api/user/cancel-order/' + id + '/',
            )
            console.log(data)

            if (data) {
                const index = state.active.orders.find((item) => item.id == id)
                state.active.orders.splice(index, 1)
            }

            return data
        },
    },
}
