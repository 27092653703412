<template lang="pug">

.footer
    .container
        //- .made Made by
        //-     a(href="https://moon.codes/" target="_blank") moon.codes
        //-     |  and
        //-     a(href="https://www.instagram.com/fionaovcharenko/" target="_blank") Fiona Ovcharenko
        .left
            .footer-item
                .logo SKLAD TRADE
                router-link.map(:to="{ name: 'contacts' }") Найти на карте
            .footer-item(v-for="column in columns")
                template(v-for="link in column")
                    router-link.link(v-if="link.to" :key="link.to" :to="link.to") {{ link.text }}
                    a.link(v-else-if="$route.name === 'catalog' || $route.name === 'cart'" :key="link.link" target="_blank") {{ link.text }}
                    a.link(v-else-if="link.link" :key="link.link" :href="link.link" target="_blank") {{ link.text }}
        .contacts
                | sklad.trade@yandex.ru
                br
                a(href="tel:+79998887766") +7(999)888-77-66
                br
                | Понельник - пятница: 9:00 - 17:00
                br
                | Суббота: 9:00 - 14:00
                br
                | Воскресенье: выходной

</template>
<script>
export default {
    data: () => ({
        links: [
            {
                text: 'Контакты',
                to: '/contacts',
            },
            {
                text: 'О доставке',
                to: '/help/delivery',
            },
            {
                text: 'О возврате товара',
                to: '/help/return',
            },
            {
                text: 'Об отмене заказа',
                to: '/help/cancel',
            },
            {
                text: 'Об оплате',
                to: '/help/payment',
            },
            {
                text: 'Помощь',
                to: '/help',
            },
            {
                text: 'Публичная оферта',
                link: '/static/offer.pdf',
            },
            {
                text: 'Политика возврата',
                link: '/static/refund.pdf',
            },
            {
                text: 'Обработка персональных данных',
                link: '/static/privacy.pdf',
            },
        ],
    }),
    computed: {
        columns() {
            let links = this.links,
                columns = []

            for (let i = 0; i < 3; i++)
                columns.push(links.slice(i * 3, (i + 1) * 3))

            return columns
        },
    },
}
</script>
<style lang="stylus" scoped>

.footer
    position absolute
    bottom 0
    background: #FFFFFF
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04)
    flex-shrink 0
    width 100%
    &-item
        width 220px
    +$phone()
        position relative

.container
    height 140px
    padding-bottom 10px
    position relative
    width 1300px
    margin auto
    display flex
    align-items center
    justify-content space-between
    +$tablet()
        width 100%
        padding 0 20px 10px
    +$phone()
        height auto
        padding-top 15px

.left
    display flex
    justify-content space-between
    align-items center
    +$phone()
        flex-direction column

.logo
    font-size 18px
    line-height 22px
    color $black
    text-transform uppercase

.map
    $bordered()
    margin-top 20px
    font-size 12px
    color $black
    border-color $blue
    display inline-flex
    align-items center
    height 29px
    border-radius 5px
    padding 1px 10px 0
    $small-shadow()
    cursor pointer
    +$phone()
        margin-bottom 10px

.link
    font-size 12px
    line-height 15px
    color $cant-be-choosen
    margin-bottom 7px
    display block
    cursor pointer
    &:last-child
        margin-bottom 0
        +$phone()
            margin-bottom 7px

.contacts
    width 250px
    font-size 14px
    line-height 22px
    color $can-push
    text-align right
    +$tablet()
        font-size 12px
        line-height 1.4
    +$phone()
        position absolute
        width calc(100% - 100px)
        right 20px
        top 13px

.made
    position absolute
    font-size 14px
    left 0
    bottom 10px
    width 100%
    text-align right
    a
        text-decoration underline
</style>
