<template lang="pug">

.popup
    .title Смена пароля
    form.form(@submit.prevent="submit")
        input.input(placeholder="Новый пароль" name="password" v-model="password" type="password" required)

        .message.error(v-if="error") Произошла ошибка

        .options
            .cancel(@click="$emit('close')") Отменить
            button.submit(type="submit") Сохранить

    //- input.input(v-model="phone" name="username" type="phone" v-mask="'+7 ### ### ##-##'" placeholder="Номер телефона" required)
    //- input.input.password(placeholder="Пароль" name="password" v-model="password" type="password" required)
    //- .meta
    //-     .remember(:class="{ active: remember }" @click="remember = !remember") Запомнить меня
    //-         g-icon(name="ui/ok")
    //-     .forget Забыли пароль?
    //- button.submit.submit-login(type="submit") Войти

</template>
<script>
import Axios from 'axios'

export default {
    mounted() {
        // this.$el.querySelector('.form .input[type="phone"]').focus()
    },
    data: () => ({
        password: null,
        error: null,
    }),
    methods: {
        async submit() {
            if (!this.password) return null

            this.error = null

            const { data } = await Axios.post('/api/auth/change-password/', {
                password: this.password,
            })

            if (data === true)
                this.$store.commit('other/popup', { name: null, query: null })
            else if (data === false) this.error = true
        },
    },
}
</script>
<style lang="stylus" scoped>

.popup
    padding 20px
    position relative
    display flex
    flex-direction column

.title
    font-size 24px
    text-align center

.input
    width 100%
    height 45px
    background-color #fff
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.04), inset 0px 1px 2px rgba(0, 0, 0, 0.04)
    border-radius 10px
    font-size 18px
    color $can-push
    padding-left 16px
    border 1px solid transparent
    margin-top 20px
    transition all .1s
    &::placeholder
        color $cant-be-choosen
    &.password:valid
        padding-top 7px
    &.invalid
        border-color $red

.options
    display flex
    margin-top 30px

.cancel, .submit
    height 46px
    width 45%
    position relative
    border-radius 10px
    color $black
    $medium-shadow()
    $bordered()
    font-size 22px
    display flex
    align-items center
    cursor pointer
    text-align center
    justify-content center

.submit
    margin-left auto

.cancel
    border-color #aaa
</style>
