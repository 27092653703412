import Vue from 'vue'

import './settings'

import router from 'core/router'
import store from 'core/store'

import Index from 'templates'

store.dispatch('auth/init')

new Vue({
	el: '#app',
	router,
	store,
	render: h => h(Index)
})
