import Vue from 'vue'

import router from 'core/router'
import store from 'core/store'
import { sync } from 'vuex-router-sync'
sync(store, router)


import 'styles/base.styl'

import Icons from 'icons'
Vue.component('g-icon', Icons)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import YmapPlugin from 'vue-yandex-maps'
Vue.use(YmapPlugin, {
    apiKey: '3e051160-726b-435a-acc0-11f947b48105',
    lang: 'ru_RU',
    coordorder: 'latlong',
    version: '2.1'
})
