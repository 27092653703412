<template lang="pug">

.nav-container
    .nav(:class="fixed ? { fixed: isFixed } : null")

        template(v-if="router")
            router-link.link(v-for="link in nav" :to="link.link" :key="link.link")
                //- .picture
                g-icon(:name="link.img")
                | {{ link.name }}

        template(v-else)
            .link(v-for="link in nav" @click="linkClick(link.link)" :class="{ active: link.link == anchor }" :key="link.link")
                //- .picture
                g-icon(:name="link.img")
                | {{ link.name }}

</template>
<script>
export default {
    props: {
        nav: Array,
        router: {
            type: Boolean,
            default: true,
        },
        active: String,
        fixed: Boolean,
        anchors: Boolean,
    },
    mounted() {
        if (this.fixed) {
            this.scroll();
            window.addEventListener("scroll", this.scroll);
        }
    },
    data: () => ({
        isFixed: false,
        left: 0,
        anchor: null,
        margins: {
            fixed: 130,
            anchor: 260,
        },
    }),
    methods: {
        linkClick(link) {
            this.$emit("linkClick", link);
        },
        scroll() {
            if (this.fixed) {
                let coords = this.$el.getBoundingClientRect();
                if (!this.isFixed && coords.top < this.margins.fixed)
                    this.isFixed = true;
                else if (this.isFixed && coords.top > this.margins.fixed)
                    this.isFixed = false;
            }
            if (this.anchors) {
                let active = null;
                for (let el of this.elements)
                    if (
                        el.el.getBoundingClientRect().top <
                        window.innerHeight / 2
                    ) {
                        active = el.id;
                        break;
                    }
                this.anchor = active;
            }
        },
    },
    computed: {
        elements() {
            let elements = [];
            for (let link of this.nav)
                elements.push({
                    id: link.link,
                    el: document.querySelector(link.link),
                });

            return elements.reverse();
        },
    },
};
</script>
<style lang="stylus" scoped>

.nav-container
    position relative

.nav
    flex-shrink 0
    display flex
    align-items flex-start
    flex-direction column
    &.fixed
        position fixed
        top 130px

.link
    background $bg
    $medium-shadow()
    border-radius 10px
    font-size 18px
    color $grey
    display flex
    align-items center
    height 40px
    padding-right 15px
    margin-bottom 11px
    border 1.5px solid transparent
    transition all .2s, box-shadow .1s
    line-height 1
    cursor pointer
    white-space nowrap

    +$tablet()
        font-size 16px
        height 35px
        padding-right 10px
    &.router-link-active, &.active
        border-color $blue
        $small-shadow()
        color $can-push
        .icon
            fill $can-push
    &:hover
        box-shadow 0px 1px 2px rgba(0, 0, 0, 0.06)

.icon
    fill $grey
    width 22px
    height 22px
    margin 0 12px
    transition all .2s
    +$tablet()
        margin 0 8px
        width 20px
        height 20px
</style>
