<template lang="pug">

#reviews
    v-flickity.reviews(v-if="reviews.length > 0" ref="flickity" :options="sliderOptions")
        .review(v-for="review in reviews")
            .img(:style="{ backgroundImage: `url(${review.img})` }")
            .text
                .name {{ review.author }}
                .message « {{ review.text }} »
        //- .prev(v-if="index > 0" @click="$refs.flickity.previous()")
        .prev(v-if="index > 0")
            g-icon(name="ui/chevron")
        .next(v-if="index < reviews.length - 1" @click="$refs.flickity.next()")
            g-icon(name="ui/chevron")

</template>
<script>
import vFlickity from 'vue-flickity'
export default {
    data: (context) => ({
        index: 0,
        sliderOptions: {
            prevNextButtons: false,
            autoPlay: 5000,
            pauseAutoPlayOnHover: true,
            cellSelector: '.review',
            on: {
                select: (index) => (context.index = index),
            },
        },
    }),
    computed: {
        reviews() {
            return this.$store.getters['info/page']('home').reviews
        },
    },
    components: {
        vFlickity,
    },
}
</script>
<style lang="stylus" scoped>

.reviews
    position relative
    width calc(100% + 20px)
    left -10px
    margin-top 130px
    +$tablet()
        margin-top 60px
    +$phone()
        left 0
        width 100%

.review
    width calc(100% - 20px)
    padding 50px 60px
    margin 10px
    border-radius 20px
    display flex
    align-items center
    justify-content center
    background-color $bg-choosen
    $small-shadow()
    +$tablet()
        padding 30px 30px
    +$phone()
        flex-direction column

.img
    width 230px
    height 230px
    flex-shrink 0
    border-radius 100%
    background-color $cant-be-choosen
    background-size cover
    background-position center
    +$tablet()
        width 160px
        height 160px
    +$phone()
        width 130px
        height 130px

.text
    padding-left 75px
    padding-right 40px
    +$phone()
        padding-left 0
        padding-right 0

.name
    font-size 28px
    line-height 34px
    color $cant-be-choosen
    +$tablet()
        font-size 24px
    +$phone()
        font-size 22px
        margin-top 20px
        font-size 16px
        line-height 1.2
        text-align center

.message
    margin-top 20px
    font-size 32px
    line-height 39px
    color $black
    +$tablet()
        font-size 26px
        line-height 1.3
    +$phone()
        text-align center
        font-size 16px

>>> .flickity-page-dots
    bottom -15px
    .dot
        opacity 1
        width 7px
        height 7px
        background-color $light-grey
        transition background-color .2s
        margin 0 5px
        &.is-selected
            background-color $blue

.next, .prev
    position absolute
    top 50%
    margin-top -15px
    cursor pointer
    transition all .1s
    .icon
        width 30px
        fill $minus
    &:hover
        opacity .8

.prev
    left -35px
    transform rotate(-90deg)
    +$phone()
        left -25px
.next
    right -35px
    transform rotate(90deg)
    +$phone()
        right -25px
</style>
