<template lang="pug">

.info
    .column
        .title Номер заказа: {{ order.id }}
        .info-item
            span Заказано:
            span {{ order.created }}
        .info-item
            span Готово:
            span {{ order.date }}
        .info-item
            span(v-if="order.delivery") Адрес доставки:
            span(v-else) Адрес склада:
            span {{ order.address }}
    .column
        .title.collect(v-if="order.status == 0") Заказ собирается
        .title.ready(v-else-if="order.status == 1") Заказ готов к получению
        .title.ended(v-else-if="order.status == 2") Заказ завершён
        .info-item
            span Сумма покупки:
            span {{ order.sum }} ₽
        .info-item
            span Количество наименований:
            span {{ order.count }} ед
        //- .subtitle
        //-     span Количество полученных баллов:
        //-     span {{ order.points }}
    .help Вы можете продублировать товары из заказа себе в корзину

</template>
<script>
export default {
    props: {
        order: Object
    },
    computed: {
    }
}
</script>
<style lang="stylus" scoped>

.info
    display flex
    justify-content space-between

.column
    width 320px
    flex-shrink 0
    &:nth-child(2)
        padding-right 40px
        width 310px

.title
    font-size 22px
    line-height 27px
    color $can-push
    margin-bottom 19px
    &.collect
        color $cant-be-choosen
    &.ready
        color $green

.info-item
    font-size 16px
    line-height 17px
    color $can-choose
    display flex
    justify-content space-between
    margin-bottom 6px
    span
        &:last-child
            text-align right

</style>
