<template lang="pug">

v-nav(:nav="nav" :router="false" :fixed="true" :anchors="true" @linkClick="linkClick")

</template>
<script>
import vNav from "ui/nav";
export default {
    data: () => ({
        nav: [
            // {
            //     link: '#ads',
            //     img: 'home/ads',
            //     name: 'Акции',
            // },
            {
                link: "#about",
                img: "home/about",
                name: "О нас",
            },
            {
                link: "#reviews",
                img: "home/review",
                name: "Отзывы",
            },
            {
                link: "#contacts",
                img: "home/map",
                name: "Контакты",
            },
        ],
    }),
    methods: {
        linkClick(link) {
            this.$scrollTo(link, 300, { offset: -window.innerHeight / 4 });
        },
    },
    components: {
        vNav,
    },
};
</script>
<style lang="stylus" scoped>

.nav-container
    position absolute
    left 0
    top 0
    +$phone()
        display none
</style>
