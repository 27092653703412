<template lang="pug">

.options
    .cancel
        .cancel-link(v-if="status == 2") Оформить возврат заказа
        .cancel-link(v-else @click="$emit('cancel')") Оформить отмену заказа
        .cancel-link(v-if="status == 0") Оформить измнение заказа

</template>
<script>
export default {
    props: {
        status: Number,
    },
}
</script>
<style lang="stylus" scoped>

.options
    margin-top 20px
    display flex
    justify-content space-between
    align-items flex-end

.cancel
    display flex
    &-link
        font-size 14px
        color $can-choose
        cursor pointer
        text-decoration underline
        margin-right 20px

.btns
    display flex
</style>
