<template lang="pug">

.welcome#welcome
    .text
        h1.title {{ welcome.title }}
            p {{ welcome.title_accent }}
        h3.subtitle {{ welcome.text }}
        .btn(v-scroll-to="{ el: '#about', offset: 0 }") {{ welcome.link_text }}
    img.img(src="~img/welcome.svg")

</template>
<script>
export default {
    computed: {
        welcome() {
            return this.$store.getters['info/page']('home').welcome
        },
    },
}
</script>
<style lang="stylus" scoped>

.welcome
    position relative
    padding-bottom 220px
    margin-top -20px
    +$tablet()
        padding-bottom 170px

    +$phone()
        padding-bottom 50px

.text
    padding-top 120px
    width 470px
    position relative
    z-index 2
    +$tablet()
        padding-top 90px
    +$phone()
        width 100%
        padding-top 100px
        text-align center

.title
    font-size 50px
    line-height 61px
    color $can-push
    font-weight normal
    +$tablet()
        font-size 40px
        line-height 1.2
    +$phone()
        font-size 32px
    p
        color $green

.subtitle
    font-weight normal
    margin-top 30px
    font-size 24px
    line-height 29px
    color $grey
    +$tablet()
        font-size 22px
        line-height 1.2
        margin-top 25px
    +$phone()
        font-size 16px
        margin-top 15px

.btn
    $btn()
    margin-top 60px
    font-size 24px
    height 55px
    padding 0 15px
    border-radius 8px
    border-width 2px
    +$tablet()
        margin-top 40px
        font-size 22px
        height 50px
    +$phone()
        font-size 18px
        height 40px
        margin-top 20px

.img
    position absolute
    width 100%
    right -60px
    top -10px
    right -5px
    top 0
    +$tablet()
        right 0
        // width 90%
    +$phone()
        width 110%
        width 180%
        left -4%
        left -75%
        opacity .3
        top -6%
        // display none
</style>
