<template lang="pug">

.storages
    .radio
        .title(v-if="title") {{ title }}
        .address(v-for="(storage, index) in storages" :class="{ active: active == index }")
            .name(@click="active = index")
                .dot
                | {{ storage.address }}
            .info(v-if="active == index")
                .tel(v-for="operator in storage.operators")
                    g-icon(name="ui/phone")
                    | {{ operator.tel }} - {{ operator.name }}
                //- .mail
                //-     g-icon(name="ui/mail")
                //-     | {{ storage.mail }}
                .btns
                    .btn.gis 2ГИС
                    .btn.yandex Яндекс Карты
                    .btn.google Google Карты

    v-map(v-if="storages.length > 0" :storages="storages" :active="active" @setActive="active = $event")

</template>
<script>
import vMap from "./map";
export default {
    props: {
        storages: Array,
        title: String,
    },
    data: (context) => ({
        active: 0,
    }),
    components: {
        vMap,
    },
};
</script>
<style lang="stylus" scoped>

.storages
    display flex
    justify-content space-between
    align-items center
    position relative
    +$phone()
        flex-direction column

.radio
    width 510px
    flex-shrink 0
    +$tablet()
        width 400px
    +$phone()
        width 100%

.title
    font-size 28px
    line-height 34px
    color $can-push
    margin-bottom 30px

.address
    margin-bottom 15px
    &:last-child
        margin-bottom 0
    &.active
        .name
            color $can-push
        .dot
            border-color $bg-choosen
            $small-shadow()
            background-color $green

.name
    font-size 20px
    line-height 1.2
    color $cant-be-choosen
    position relative
    display flex
    align-items center
    transition all .2s
    cursor pointer
    +$tablet()
        font-size 18px

.dot
    width 22px
    height 22px
    border-radius 100%
    border 3px solid $light-grey
    margin-right 9px
    transition all .2s
    +$tablet()
        width 18px
        height 18px

.info
    padding 30px 30px 15px
    +$tablet()
        padding-right 15px
        padding-left 25px
        padding-top 20px
        padding-bottom 20px

.tel, .mail
    font-size 16px
    line-height 19px
    display flex
    align-items center
    margin-bottom 15px
    .icon
        fill $can-choose

.tel
    color $can-choose
    .icon
        width 16px
        margin-right 9px

.mail
    color $blue
    text-decoration underline
    .icon
        width 18px
        margin-right 7px

.btn
    $btn()
    font-size 20px
    border-radius 10px
    height 44px
    padding 0 12px
    margin-top 10px
    margin-right 20px
    +$tablet()
        font-size 18px
        height 40px
        padding 0 10px
        margin-right 15px
        // margin-top 5px
    &.yandex
        border-color $red
    &.gis
        border-color $green
</style>
