<template lang="pug">

.home(:class="{ loading: !loaded }")
    v-welcome
    .container
        v-nav
        .inner
            //- v-ads
            v-about
            v-reviews
            v-contacts

</template>
<script>
import vWelcome from "./welcome";
import vNav from "./nav";
import vAds from "./ads";
import vAbout from "./about";
import vReviews from "./reviews";
import vContacts from "./contacts";
export default {
    computed: {
        loaded() {
            return this.$store.getters["info/page"]("home").loaded;
        },
    },
    components: {
        vWelcome,
        vNav,
        vAds,
        vAbout,
        vReviews,
        vContacts,
    },
};
</script>
<style lang="stylus" scoped>

// .home
//     padding 0 70px

.container
    position relative

.inner
    position relative
    width 1080px
    left 220px
    +$tablet()
        width calc(100% - 150px)
        left 150px
    +$phone()
        width 100%
        left 0
</style>
