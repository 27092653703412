import Axios from 'axios'
import router from 'core/router'

export default {
    namespaced: true,
    state: () => ({
        popup: {
            name: null,
            query: null
        },
    }),
    getters: {
        popup(state, getters, rootState) {
            return {
                name: rootState.route.query.popup,
                query: rootState.route.query.query
            }
        },
    },
    mutations: {
        popup({ popup }, { name = null, query = null }) {
            if (name)
                router.push({ query: { popup: name, query } })
            else
                router.push({ query: null })
            popup.name = name
            popup.query = query
        },
    },
    actions: {}
}
